<template>
	<div class="bodily">
		<!-- <h3 class="qjc-fts-16 qjc-c-dark qjc-ftw-500">产妇信息</h3> -->
		<div class="insert-title">{{$t('open.m4')}}</div>
		
		<h4 class="qjc-fts-14 qjc-c-dark qjc-ftw-500">{{$t('open.m5')}}</h4>
		<el-radio v-model="hasPredis2" label="0" class="qjc-wid-100">{{$t('open.q20')}}</el-radio>
		<el-radio v-model="hasPredis2" label="1" class="qjc-wid-100">{{$t('open.q30')}}</el-radio>
		
		<el-checkbox-group
			v-model="formData.predis2"
			class="predis_list"
			:disabled="hasPredis2==0?true:false"
		>
			<el-checkbox v-for="item in list" :key="item.val" :label="item.val" :title="item.en_name">{{ item.en_name }}</el-checkbox>
		</el-checkbox-group>
		
		<div class="footer-btn">
			<el-button @click="handlePrev" class="prev qjc-fts-16 qjc-block">{{$t('open.t12')}}</el-button>
			<el-button @click="next" class="next qjc-fts-16 qjc-block">{{$t('open.t13')}}</el-button>
		</div>
		
	</div>
</template>

<script>
	export default{
		name: 'puerpera',
		data() {
			return {
				list: [
					{ val: 'DIS01', name: '卵巢功能障碍', en_name: 'Ovarian dysfunction' },
					{ val: 'DIS02', name: '感冒>=3次', en_name: 'Cold >= 3 times' },
					{ val: 'DIS03', name: '头颈疾病', en_name: 'Head and Neck Diseases' },
					{ val: 'DIS04', name: '营养缺乏', en_name: 'nutritional deficiency' },
					{ val: 'DIS05', name: '高血压疾病', en_name: 'Hypertensive diseases' },
					{ val: 'DIS06', name: '慢性肝病', en_name: 'chronic liver disease' },
					{ val: 'DIS07', name: '消化道疾病<3次', en_name: 'Digestive tract diseases <3 times' },
					{ val: 'DIS08', name: '流产', en_name: 'had a miscarriage before' },
					{ val: 'DIS09', name: '脑血管疾病', en_name: 'cerebrovascular disease' },
					{ val: 'DIS10', name: '女性生殖器官肿瘤', en_name: 'Tumors of the female genital organs' },
					{ val: 'DIS11', name: '关节疾患', en_name: 'joint disorder' },
					{ val: 'DIS12', name: '糖尿病', en_name: 'diabetes' },
					{ val: 'DIS13', name: '慢性阻塞性肺部疾病(COPD)', en_name: 'Chronic Obstructive Pulmonary Disease (COPD)' },
					{ val: 'DIS14', name: '泌尿系统疾患(除肾脏外)', en_name: 'Urological disorders (not include kidney)' },
					{ val: 'DIS15', name: '肾脏疾病', en_name: 'kidney disease' },
					{ val: 'DIS16', name: '子宫、卵巢相关癌症', en_name: 'Uterus and ovary related cancers' },
					{ val: 'DIS17', name: '妊娠疾病', en_name: 'Diseases of pregnancy' },
					{ val: 'DIS18', name: '癫痫', en_name: 'seizures' },
					{ val: 'DIS19', name: '精神疾病+忧郁症', en_name: 'Mental illness + depression' },
					{ val: 'DIS20', name: '甲状腺疾患', en_name: 'thyroid disorder' },
					{ val: 'DIS21', name: '眼部疾患', en_name: 'eye disease' },
					{ val: 'DIS22', name: '感冒<3次', en_name: 'Cold < 3 times' },
					{ val: 'DIS23', name: '贫血', en_name: 'anemic' },
					{ val: 'DIS24', name: '肥胖及营养过度', en_name: 'Obesity and overnutrition' },
					{ val: 'DIS25', name: '皮肤疾病', en_name: 'Skin Diseases' },
					{ val: 'DIS26', name: '恶性肿瘤', en_name: 'malignant tumor' },
					{ val: 'DIS27', name: '心脏病', en_name: 'heart attack' },
					{ val: 'DIS28', name: '消化道疾病>=3次', en_name: 'Digestive tract diseases &gt;= 3 times' },
					{ val: 'DIS29', name: '女性生理期疾患', en_name: 'Female physiological disorders' },
					{ val: 'DIS30', name: '头痛', en_name: 'have a headache' },
					{ val: 'DIS31', name: '痔疮', en_name: 'hemorrhoids' },
					{ val: 'DIS32', name: '口腔疾患>=3次', en_name: 'Oral diseases &gt;= 3 times' },
					{ val: 'DIS33', name: '痛风', en_name: 'gout' },
					{ val: 'DIS34', name: '良性肿瘤', en_name: 'benign tumor' },
					{ val: 'DIS35', name: '乳癌', en_name: 'breast cancer' },
					{ val: 'DIS36', name: '腹部骨盆疾病', en_name: 'Abdominal Pelvic Diseases' },
					{ val: 'DIS37', name: '乳房疾患', en_name: 'breast disease' },
					{ val: 'DIS38', name: '霉菌病', en_name: 'mycosis' },
					{ val: 'DIS39', name: '口腔疾患<3次', en_name: 'Oral disease &lt;3 times' },
					{ val: 'DIS41', name: '肺炎', en_name: 'inflammation of the lungs' }
				],
			
				hasPredis2: '0'// 是否患病
				
			}
		},
		props: ['formData'],
		created() {
			this.hasPredis2 = (this.formData.predis2 && this.formData.predis2.length>0)?'1':'0'// 是否患病
		},
		watch: {
			hasPredis2(val) {// 选择无疾病时清空已选项
				if(val == '0'){
					this.formData.predis2 = [];
				}
			}
		},
		methods: {
			next() {
				if(this.hasPredis2 == '0' && this.formData.predis2.length > 0){
					this.$myAlert({
						type: 'fail',
						title: '选择错误'
					});
				}else if(this.hasPredis2 == '1' && this.formData.predis2.length == 0){
					this.$myAlert({
						type: 'fail',
						title: '选择错误',
						content: '请选择至少一种疾病或无患病'
					});
				}else{
					console.log(this.formData);
					this.$emit('checkTab', 'Newborn');
				}
			},
			handlePrev() {
				this.$emit('checkTab', 'myBodily');
			},
		}
	}
</script>

<style scoped>
	.el-radio >>> .el-radio__label,
	.el-checkbox >>> .el-checkbox__label{
		font-size: 14px;
		line-height: 14px;
		color: #777F8F;
	}
	.el-checkbox >>> .el-checkbox__label{
		padding-left: 6px;
	}
	.el-form >>> .el-radio.is-bordered.is-checked {
		border-color: #6681FA
	}
	.predis_list {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-items: center;
	}
	
	.predis_list >>> .el-checkbox__label, .el-checkbox__input {
		white-space: pre-wrap !important;
	}
	.predis_list >>> .el-checkbox__input {
		white-space: pre-wrap !important;
	}
</style>
<style lang="scss" scoped>
	.bodily{
		padding-top: 32px;
		
		.insert-title {
			position: relative;
			font-size: 16px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #293033;
			line-height: 16px;
			padding-bottom: 15px;
			border-bottom: 1px solid #E5E8F5;
			margin-bottom: 32px;
			
			&::before{
				position: absolute;
				left: -24px;
				top: 0;
				content: '';
				width: 4px;
				height: 20px;
				background-color: #6883FB;
			}
		}
		
		&>h4{
			margin: 24px 0 16px;
		}
		
		.el-radio{
			margin-bottom: 16px;
			margin-right: 20px;
		}
		
		.el-checkbox-group{
			background-color: #F2F4FD;
			border: 1px solid #E5E9F9;
			border-radius: 4px;
			
			.el-checkbox{
				width: 16.666%;
				// line-height: 14px;
				min-height: 60px;
				margin-right: 0;
				padding: 11px 16px;
				border-bottom: 1px solid #fff;
				// overflow: hidden;
				display: flex;
				align-items: center;
				white-space: wrap !important;
				
				&:not(:nth-child(6n)){
					border-right: 1px solid #fff;
				}
			}
		}
		
		.footer-btn {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-top: 40px;
		}
				
		.next{
			// width: 96px;
			padding: 0 10px;
			height: 34px;
			border-radius: 17px;
			border: none;
			font-size: 14px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #fff;
		}
		.prev {
			// width: 96px;
			padding: 0 10px;
			height: 34px;
			border-radius: 17px;
			border: 1px solid #6883FB;
			font-size: 14px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #6883FB;
			background-color: #fff;
			margin-right: 24px;
		}
	}
</style>
